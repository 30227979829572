import React, { useCallback, useRef } from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Button, Col, Container, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"

import * as css from "./ruko-kosong.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { Link } from "@gatsbyjs/reach-router"
import MetaTags from "../components/MetaTags"
import metaPreviewImage from "../assets/images/thumbnails/thumbnail_index.jpg"
import layer1 from "../assets/images/ruko-kosong/layer1.png"
import cat1 from "../assets/images/ruko-kosong/cat1.jpg"
import ModalUploadCloseUpPhoto from "../components/ruko-kosong/ModalUploadCloseUpPhoto"
import ModalDownload from "../components/ruko-kosong/ModalDownload"

// markup
const RukoKosong = () => {

    const [input1, setInput1] = React.useState('Bonbon');
    const [input2, setInput2] = React.useState('2000');
    const [isUploadCloseUpPhotoModalVisible, setIsUploadCloseUpPhotoModalVisible] = React.useState(false);
    const [isDownloadModalVisible, setIsDownloadModalVisible] = React.useState(false);

    const [image, setImage] = React.useState<string | ArrayBuffer | null>(cat1)


    const canvasRef: any = useRef(null)
    let ctx: any = null;

    // initialize the canvas context
    React.useEffect(() => {
        // dynamically assign the width and height to canvas
        const canvasEle: any = canvasRef.current;
        if (canvasEle == null) {
            return;
        }

        canvasEle.width = canvasEle.clientWidth;
        canvasEle.height = canvasEle.clientHeight;

        // get context of the canvas
        ctx = canvasEle.getContext("2d");
    }, [input1, input2, image, ctx]);

    React.useEffect(() => {
        if (ctx != null) {

            addLayer1(input1, input2, image)
        }
    }, [input1, input2, image, ctx]);

    function addLayer1(input1: any, input2: any, imageTmp: any) {
        const image1 = new Image();
        image1.src = imageTmp;
        image1.onload = () => {
            ctx.drawImage(image1, 165, 54, 165, 165);

            const image = new Image();
            image.src = layer1;
            image.onload = () => {
                ctx.drawImage(image, 0, 0, 490, 490);
                writeText({ text: input1, x: 250, y: 340 });
                writeText({ text: 'KUCING PENJAGA RUKO KOSONG - SINCE ' + input2, x: 250, y: 423 }, {
                    fontSize: '16',
                    lineWidth: '0',
                });
                writeText({ text: 'ktpanabul.com/ruko-kosong', x: 250, y: 450 }, {
                    fontSize: '12',
                    lineWidth: '0',
                    color: 'rgba(0,0,0,0.2)'
                });
            };
        };


    }

    // write a text
    const writeText = (info: any, style: any = {}) => {
        const { text, x, y } = info;
        const {
            fontSize = 90,
            fontFamily = 'aqum',
            color = '#ffffff',
            textAlign = 'center',
            textBaseline = 'top',
            lineWidth = 8
        } = style;

        ctx.beginPath();
        ctx.font = fontSize + 'px ' + fontFamily;
        ctx.textAlign = textAlign;
        ctx.textBaseline = textBaseline;
        ctx.fillStyle = color;
        ctx.strokeStyle = 'black';
        ctx.lineWidth = lineWidth;
        if (lineWidth > 0) {
            ctx.strokeText(text, x, y);
        }
        ctx.fillText(text, x, y);
        ctx.stroke();
    }

    function download() {
        if (canvasRef == null) {
            return;
        }
        if (canvasRef.current == null) {
            return;
        }
        var canvas = document.getElementById("canvas");
        var url = canvasRef.current.toDataURL("image/png");
        var link = document.createElement('a');
        link.download = 'hati-hati-kalo-hati-kelamaan-kosong-nanti-jadi-kedai-teh.png';
        link.href = url;
        link.click();
    }


    const availableYear = []
    for (let i = 2000; i < 2022; i++) {
        availableYear.push(i)
    }

    return (
        <>
            <MetaTags
                metaDescription="Penghuni Ruko Kosong"
                metaTitle="Penghuni Ruko Kosong"
                metaPath="/ruko-kosong"
                metaPreviewImage={metaPreviewImage}
            />

            <div className={css.section}>
                <div className={css.canopy}></div>
                <h1>Kucing Penjaga Ruko Kosong</h1>
                <canvas style={{ width: '500px', height: '500px' }} ref={canvasRef} />

                <br />


                <div className={css.step2}>
                    1. Ketik Nama Anabul & tentukan tahun lahir
                </div>

                <div className={css.inputs}>
                    <input
                        type="text"
                        value={input1}
                        className={css.input1}
                        onChange={(e) => {
                            setInput1(e.currentTarget.value);
                            console.log(input1)
                        }}
                    />

                    <select
                        defaultValue={input2}
                        name="since" id="since"
                        onChange={(e) => {
                            setInput2(Number(e.target.value).toString());
                        }}
                    >
                        {
                            availableYear.map((item) => {
                                return (<option value={item}>{item}</option>)
                            })
                        }

                    </select>
                </div>


                <div className={css.step1}>
                    2. Pilih Foto Anabul Kamu.
                </div>
                <Button onClick={() => setIsUploadCloseUpPhotoModalVisible(true)} className={css.uploadBtn}>
                    Pilih Foto 📸
                </Button>


                <div className={css.step3}>
                    3. Gambar Siap Unduh!
                </div>


                <Button onClick={() => setIsDownloadModalVisible(true)} className={css.btn}>
                    Unduh ✨
                </Button>

                {/* <Button onClick={() => download()} className={css.btn}>
                    Unduh ✨
                </Button> */}

                <div className={css.createdBy}>
                </div>

                <div className={css.visitor}>
                    <span>JUMLAH PENGUNJUNG</span>
                    <img src="https://counter5.optistats.ovh/private/freecounterstat.php?c=d4626t22ah1h4dus7x9a755jng2zn2mr" title="free website hit counters" alt="free website hit counters"></img>
                </div>

                <div className={css.warning}>
                    Aplikasi ini tidak disponsori oleh pihak terkait, dan semua data yang kamu input tidak diunggah ke server.
                </div>

            </div>

            {/* Upload Close-up Modal */}
            <ModalUploadCloseUpPhoto
                setImage={setImage}
                isUploadCloseUpPhotoModalVisible={isUploadCloseUpPhotoModalVisible}
                setIsUploadCloseUpPhotoModalVisible={setIsUploadCloseUpPhotoModalVisible}
            />
            <ModalDownload
                download={download}
                isDownloadModalVisible={isDownloadModalVisible}
                setIsDownloadModalVisible={setIsDownloadModalVisible}
            />

        </>
    )
}

export default RukoKosong

// extracted by mini-css-extract-plugin
export var bgRemoveToggle = "ModalUploadCloseUpPhoto-module--bgRemoveToggle--7c861";
export var btnContainers = "ModalUploadCloseUpPhoto-module--btnContainers--031e7";
export var container = "ModalUploadCloseUpPhoto-module--container--3f6a7";
export var containerCropper = "ModalUploadCloseUpPhoto-module--containerCropper--c5a73";
export var cropArea = "ModalUploadCloseUpPhoto-module--cropArea--b4f1a";
export var dotLoading = "ModalUploadCloseUpPhoto-module--dotLoading--3ab23";
export var dots = "ModalUploadCloseUpPhoto-module--dots--4a05b";
export var dragPhotos = "ModalUploadCloseUpPhoto-module--dragPhotos--659cf";
export var footerModalContainer = "ModalUploadCloseUpPhoto-module--footerModalContainer--919a9";
export var message = "ModalUploadCloseUpPhoto-module--message--17837";
export var modalContent = "ModalUploadCloseUpPhoto-module--modalContent--fbc08";
export var sheen = "ModalUploadCloseUpPhoto-module--sheen--700c6";
export var shinnyButton = "ModalUploadCloseUpPhoto-module--shinnyButton--2a1c0";
export var slider = "ModalUploadCloseUpPhoto-module--slider--f423d";
export var uploadInfo = "ModalUploadCloseUpPhoto-module--uploadInfo--441a9";

import { Link } from "@gatsbyjs/reach-router"
import React from "react"
import { Button, Modal } from "react-bootstrap"
import useInterval from "use-interval"

import * as css from "../../pages/ruko-kosong.module.css"
import HeroSwiper from "../hero-swiper/HeroSwiper"

const ModalDownload = (props: any) => {
    const [timers, setTimers] = React.useState(10);

    useInterval(() => {
        setTimers(timers - 1)
    }, 1000)

    React.useEffect(() => {
        setTimers(10)
    }, [])

    return (
        <>
            <Modal
                show={props.isDownloadModalVisible}
                size="lg"
                onHide={() => {
                    setTimers(10)
                    props.setIsDownloadModalVisible(false)
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={css.modalContent}>
                    <div className={css.promo}>
                        <div className={css.title}>
                            Apakah Kucing kamu sudah punya KTP?
                        </div>
                        <Link className={css.action} to={'/features/'}>
                            Buat Sekarang! ✨
                        </Link>
                        <HeroSwiper swiperOnly={true} />
                    </div>
                </Modal.Body>
                <Modal.Footer className={css.footerModal}>
                    {
                        timers > 0 && (
                            <div className={css.timer}>
                                Sedang membuat gambar, harap tunggu {timers} detik...

                            </div>
                        )
                    }


                    <Button onClick={() => props.download()} className={css.btnDownload}
                        disabled={(timers > 0)}
                        data-disabled={(timers > 0)}
                    >
                        Unduh Penjaga Ruko
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalDownload

import { faCircleNotch, faPhotoFilm } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Button, Form, Modal } from "react-bootstrap"
import Cropper from "react-easy-crop"

import { Range } from "react-range"
import useAuth from "../../hooks/useAuth"
import { generateCroppedImage } from "../close-up-upload/cropImage"

import * as globalCss from "../styles/global.module.css"
import * as styles from "./ModalUploadCloseUpPhoto.module.css"
import log from "../log"
import { toast } from "react-toastify"
const axios = require("axios").default

const ModalUploadCloseUpPhoto = (props: any) => {
    let endpoint = process.env.APP_API_ENDPOINT
    const { isAuthenticated, accessToken } = useAuth()

    // @ts-ignore
    const inputRef = React.useRef()
    // @ts-ignore
    const triggerFileSelectPopup = () => inputRef.current.click()

    const [image, setImage] = React.useState<string | ArrayBuffer | null>(null)
    const [croppedArea, setCroppedArea] = React.useState(null)
    const [crop, setCrop] = React.useState({ x: 0, y: 0 })
    const [zoom, setZoom] = React.useState(1)

    // Upload
    const [croppedImageBlobUrl, setCroppedImageBlobUrl] = React.useState("")
    const [isUploadLoading, setIsUploadLoading] = React.useState(false)

    const onCropComplete = (croppedAreaPercentage: any, croppedAreaPixels: any) => {
        setCroppedArea(croppedAreaPixels)
    }

    React.useEffect(() => {
        if (croppedImageBlobUrl != "") {
            props.setImage(croppedImageBlobUrl)
            props.setIsUploadCloseUpPhotoModalVisible(false)
        }
    }, [croppedImageBlobUrl])


    const onCrop = async () => {
        generateCroppedImage(image, croppedArea, setCroppedImageBlobUrl)
    }

    const onSelectFile = (event: any) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader()
            reader.readAsDataURL(event.target.files[0])
            reader.addEventListener("load", () => {
                setImage(reader.result)
            })
        }
    }


    // drag state
    const [dragActive, setDragActive] = React.useState(false);

    // handle drag events
    const handleDrag = function (e: any) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e: any) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            // at least one file has been dropped so do something
            const reader = new FileReader()
            reader.readAsDataURL(e.dataTransfer.files[0])
            reader.addEventListener("load", () => {
                setImage(reader.result)
            })
        }
    };

    const [isAiRemovalEnabled, setIsAiRemovalEnabled] = React.useState(true);
    const [allowedFileFormat, setAllowedFileFormat] = React.useState('image/*');

    React.useEffect(() => {
        if (isAiRemovalEnabled) {
            setAllowedFileFormat('image/*')
        } else {
            setAllowedFileFormat('image/png')
        }
    }, [isAiRemovalEnabled])


    return (
        <>
            <Modal
                show={props.isUploadCloseUpPhotoModalVisible}
                className="modal fade"
                size="lg"
                onHide={() => {
                    props.setIsUploadCloseUpPhotoModalVisible(false)
                    setImage(null)
                }}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Pilih Foto



                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={styles.modalContent}>
                    {/*  @ts-ignore */}

                    <input type="file" accept={allowedFileFormat} ref={inputRef} onChange={onSelectFile} style={{ display: "none" }} />
                    {image ? null : (
                        <div
                            className={styles.dragPhotos}
                            onClick={triggerFileSelectPopup}
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}
                            data-is-on-drag={dragActive}
                        >
                            <FontAwesomeIcon icon={faPhotoFilm} />
                            <>Pilih Foto</>
                            <div className={styles.uploadInfo}>
                                {
                                    isAiRemovalEnabled ? (
                                        <>File disarankan berformat JPG</>
                                    ) : (
                                        <>Gambar harus berformat PNG dengan latar belakang yang sudah dihapus.</>
                                    )
                                }
                            </div>
                        </div>
                    )}
                    {image ? (
                        <>

                            <div className={styles.containerCropper}>
                                <div className={styles.container}>
                                    <Cropper
                                        image={typeof image == 'string' ? image : undefined}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={1}
                                        onCropChange={setCrop}
                                        onZoomChange={setZoom}
                                        onCropComplete={onCropComplete}
                                        showGrid={false}
                                        classes={{
                                            cropAreaClassName: styles.cropArea,
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    ) : null}
                </Modal.Body>
                {image ? (
                    <div className={styles.footerModalContainer}>
                        <Modal.Footer>
                            {image ? (
                                <>
                                    {isUploadLoading === true ? null : (
                                        <div className={styles.slider}>
                                            <Range
                                                min={1}
                                                max={3}
                                                step={0.1}
                                                values={[zoom]}
                                                onChange={values => setZoom(values[0])}
                                                renderTrack={({ props, children }) => (
                                                    <div
                                                        {...props}
                                                        style={{
                                                            ...props.style,
                                                            height: "6px",
                                                            width: "100%",
                                                            backgroundColor: "#eee",
                                                            borderRadius: "30px",
                                                        }}
                                                    >
                                                        {children}
                                                    </div>
                                                )}
                                                renderThumb={({ props }) => (
                                                    <div
                                                        {...props}
                                                        style={{
                                                            ...props.style,
                                                            height: "25px",
                                                            width: "25px",
                                                            backgroundColor: "#6db9ff",
                                                            borderRadius: "400px",
                                                            boxShadow: "1px 1px 8px rgba(0,0,0,.3)",
                                                            border: "3px solid #fff",
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    )}
                                </>
                            ) : null}
                            <div className={styles.btnContainers}>
                                {image ? (
                                    <>
                                        {isUploadLoading === true ? (
                                            <>
                                                <Button variant="primary" disabled={true}>
                                                    <div className={styles.dotLoading}>Sedang Mengunggah</div>
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Button variant="primary" onClick={triggerFileSelectPopup} style={{ marginRight: "10px" }}>
                                                    Pilih Foto Lain
                                                </Button>
                                                <Button variant="primary" onClick={onCrop}>
                                                    Selesai
                                                </Button>
                                            </>
                                        )}
                                    </>
                                ) : null}
                            </div>
                        </Modal.Footer>
                    </div>
                ) : null}
            </Modal>
        </>
    )
}

export default ModalUploadCloseUpPhoto
